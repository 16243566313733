LogoutCtrl.$inject = [
  "$scope",
  "$location",
  "Login",
  "Auth2",
  "$timeout",
  "CONSTANTS",
];
export default function LogoutCtrl(
  $scope,
  $location,
  Login,
  Auth2,
  $timeout,
  CONSTANTS
) {
  $scope.message = CONSTANTS.byeMessage;
  Login.IsLoggedOut();

  Auth2.check({}, function () {
    Login.IsLoggedOut();
    $timeout(function () {
      $location.path("login");
    }, 4000);
  });
}
