angular
  .module("kitstajm")
  .controller(
    "ProjectCtrl",
    function ($scope, Projectss, Employees, Customers, Activities) {
      $scope.employeesData = Employees;
      $scope.customersData = Customers;
      $scope.projectsData = Projectss;
      $scope.activitiesData = Activities;

      Projectss.projectDropdownSettings = {
        smartButtonMaxItems: 1,
        styleActive: true,
        checkBoxes: false,
        alwaysOpened: false,
        displayProp: "project_name",
        selectionLimit: 1,
        showCheckAll: false,
        showUncheckAll: false,
        closeOnSelect: true,
        scrollableHeight: "300px",
        scrollable: true,
        enableSearch: true,
        searchField: "project_name",
      };

      Projectss.projectEvents = {
        onItemSelect: function (item) {
          let selid = $scope.findIndexById(
            Projectss.projects.data,
            "project_id",
            item.project_id
          );

          Projectss.projects.selProj = Projectss.projects.data[selid];
          Projectss.projCopy(
            Projectss.projects.selProj,
            Projectss.projects.editProj
          );
          Projectss.projects.index = selid;

          Activities.updateProjectActivities(
            Projectss.projects.selProj.project_id,
            null
          );
        },
        onItemDeselect: function (item) {
          console.log("Item deselected:", item);
        },
      };

      $scope.saveProj = function (arr) {
        if (typeof arr === "undefined") {
          return;
        }
        if (typeof arr.editProj.project_id !== "undefined") {
          var tmpCustomer = arr.editProj.customer;
          arr.editProj.customer = null;

          Projectss.update(
            { id: arr.editProj.project_id },
            arr.editProj,
            function (result) {
              // Save back to array obj
              arr.editProj.customer = tmpCustomer;
              $scope.projCopy(arr.editProj, arr.selProj);
            },
            function (error) {
              //console.log('err update', error);
            }
          );
        } else {
          Projectss.insert(
            {},
            arr.editProj,
            function (result) {
              // Save back to array obj
              arr.isNew = false;
              // id from DB
              arr.editProj.project_id = result.insertId;
              // add to all projects
              var x = $scope.projects.data.push(arr.editProj) - 1;
              $scope.projects.selProj = $scope.projects.data[x];

              // add to selected customer projects list
              if (typeof arr.projects === "undefined") {
                arr.projects = [];
              }
              var y = arr.projects.push($scope.projects.selProj) - 1;
              arr.projIndex = y;

              arr.selProj = arr.projects[y];
              // remove ref to new
              arr.editProj = {};
              // copy back data
              $scope.projCopy(arr.selProj, arr.editProj);

              //$scope.projCopy(arr.editProj, arr.selProj);
            },
            function (error) {
              //console.log('err update', error);
            }
          );
        }
      };

      $scope.moveProj = function (dir, arr) {
        if (typeof arr === "undefined") {
          return;
        }
        if (dir >= 1 && arr.index < arr.data.length - 1) {
          arr.selProj = arr.data[++arr.index];
          Projectss.projCopy(arr.selProj, arr.editProj);
        } else if (dir < 1 && arr.index > 0) {
          arr.selProj = arr.data[--arr.index];
          Projectss.projCopy(arr.selProj, arr.editProj);
        }
        arr.sProj[0] = arr.selProj;
        Activities.updateProjectActivities(arr.selProj.project_id, null);
      };

      $scope.cancelProj = function (arr) {
        if (typeof arr === "undefined") {
          return;
        }
        if (arr.isNew) {
          arr.isNew = false;
          arr.selProj = arr.prevSel;
        }

        delete arr.editProj;
        arr.editProj = {};
        $scope.projCopy(arr.selProj, arr.editProj);
      };

      $scope.addProj = function (arr) {
        if (typeof arr === "undefined") {
          return;
        }
        if (!arr.isNew) {
          //$scope.projects.selProj = null;
          arr.prevSel = arr.selProj;
          arr.editProj = {};
          arr.selProj = arr.editProj;
          arr.editProj.customer_id = arr.customer_id;
          arr.isNew = true;
        }
      };
    }
  );
