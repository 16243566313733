import editModal from "../invoiceReports/editModal.html";
import okModal from "./okModal.html";
import errorModal from "../error/errorModal.html";

InvoiceCtrl.$inject = [
  "$scope",
  "$q",
  "Invoice",
  "InvoiceIt",
  "ReadInvoice",
  "$filter",
  "InvoiceOp",
  "Modal",
  "Erp",
  "moment",
  "Pdf",
  "fileSaver",
  "CONSTANTS",
];
export default function InvoiceCtrl(
  $scope,
  $q,
  Invoice,
  InvoiceIt,
  ReadInvoice,
  $filter,
  InvoiceOp,
  Modal,
  Erp,
  moment,
  Pdf,
  fileSaver,
  CONSTANTS
) {
  $scope.totAmm = 0;
  $scope.formData = {};
  $scope.formData.dateTo = null;
  $scope.formData.dateFrom = null;
  $scope.opened1 = false;
  $scope.opened2 = false;

  //Datepicker
  $scope.dateOptions = {
    "year-format": "'yy'",
    "show-weeks": true,
    "starting-day": 1,
  };

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  function getBlob(statement) {
    Pdf.save({ id: 1 }, statement, function (data) {
      var base64doc = data.result;
      return b64toBlob(base64doc, "application/pdf", 512);
    });
  }

  $scope.viewPdf = function (statement) {
    Pdf.save({ id: 1 }, { statement: statement }, function (data) {
      var base64doc = data.result;
      var blob = b64toBlob(base64doc, "application/pdf", 512);
      var win = window.open("", "_blank");
      var urlCreator = URL || webkitURL;
      var pdfUrl = urlCreator.createObjectURL(blob);
      win.location.href = pdfUrl;
    });
  };

  $scope.viewPdf2 = function (statement) {
    Pdf.get({ id: 351 }, { statement: statement }, function (data) {
      var base64doc = data.result;
      var blob = b64toBlob(base64doc, "application/pdf", 512);
      var win = window.open("", "_blank");
      var urlCreator = URL || webkitURL;
      var pdfUrl = urlCreator.createObjectURL(blob);
      win.location.href = pdfUrl;
    });
  };

  $scope.downloadPdf = function (statement) {
    var st = JSON.parse(statement.statement);
    var statementType = st.statementType === "kredit" ? "kreditfaktura " : "";
    var name =
      CONSTANTS.company +
      " " +
      statementType +
      statement.invoice_number +
      " " +
      statement.customername +
      ".pdf";

    Pdf.save({ id: 1 }, { statement: statement }, function (data) {
      var base64doc = data.result;
      var blob = b64toBlob(base64doc, "application/pdf", 512);
      fileSaver.saveAs(blob, name);
    });
  };

  $scope.message = "Rapport";

  $scope.header = [
    "Kund",
    "Projekt",
    "Aktivitet",
    "Namn",
    "Timmar",
    "Timpris",
    "Summa",
  ];

  $scope.custMode = { external: true, internal: false };
  $scope.groupMode = "K";
  $scope.detailMode = "P";
  $scope.timeMode = "A";
  $scope.sumMode = "A";

  if (!$scope.formData.dateFrom) {
    if (moment().date() > 15) {
      $scope.momentStart = moment(
        moment().startOf("day").startOf("month").format("YYYY-MM-DD")
      );
    } else {
      $scope.momentStart = moment(
        moment()
          .startOf("day")
          .startOf("month")
          .add(-1, "month")
          .format("YYYY-MM-DD")
      );
    }
    $scope.formData.dateFrom = $scope.momentStart.toDate();
  }

  if (!$scope.formData.dateTo) {
    $scope.momentStop = moment($scope.momentStart)
      .add(1, "month")
      .subtract(1, "days");
    $scope.formData.dateTo = $scope.momentStop.toDate();
  }

  $scope.getHeader = function () {
    return $scope.header;
  };

  $scope.koko = function () {
    //console.log($scope.invoice);
    return $scope.invoice;
  };

  $scope.updatePeriod = function (dir) {
    $scope.momentStart = moment($scope.formData.dateFrom, "YYYY-MM-DD");
    $scope.momentStop = moment($scope.formData.dateTo, "YYYY-MM-DD");
    if (dir === -1) {
      $scope.momentStart.startOf("month").subtract(1, "months");
      $scope.momentStop.startOf("month").subtract(1, "days");
    } else {
      $scope.momentStart.startOf("month").add(1, "months");
      $scope.momentStop.startOf("month").add(2, "months").subtract(1, "days");
    }
    $scope.formData.dateFrom = $scope.momentStart.toDate();
    $scope.formData.dateTo = $scope.momentStop.toDate();
  };

  $scope.kaka = function (head, inv) {
    switch (head) {
      case "Kund":
        return (
          inv.customername + (inv.endcustomer ? "-" + inv.endcustomer : "")
        );
      case "Projekt":
        return inv.project_name;
      case "Aktivitet":
        return inv.activity_name;
      case "Namn":
        return inv.name;
      case "Timmar":
        return inv.sum;
      case "Timpris":
        return inv.timpris;
      case "Summa":
        return inv.pris;
      case "Fakt":
        return inv.invoiceit;
    }
  };

  $scope.kiki = function (head, inv) {
    switch (head) {
      case "Kund":
        return (
          inv.customername + (inv.endcustomer ? "-" + inv.endcustomer : "")
        );

      case "Projekt":
        return inv.project_name;

      case "Kund Id":
        return inv.customer_id;

      case "Projekt Id":
        return inv.project_id;

      case "Period start":
        return inv.period_start;

      case "Period stop":
        return inv.period_stop;

      case "Datum":
        return inv.inputdate;

      case "Summa":
        return inv.amount;
    }
  };

  $scope.createInvoice = function () {
    var promises = [];

    $scope.momentStart = moment($scope.formData.dateFrom);
    $scope.momentStop = moment($scope.formData.dateTo);

    var params = {
      from: $scope.momentStart.format("YYYY-MM-DD"),
      to: $scope.momentStop.format("YYYY-MM-DD"),
    };
    for (var j = 0; j < $scope.invoice.length; j++) {
      if ($scope.invoice[j].invoiceit) {
        //console.log($scope.invoice[j]);
        switch ($scope.groupMode) {
          case "K":
            params.cust_id = $scope.invoice[j].customer_id;
            params.proj_id = $scope.invoice[j].project_id;
            params.act_id = $scope.invoice[j].activity_id;
            params.emp_id = $scope.invoice[j].employee_id;
            break;
          case "A":
            params.cust_id = $scope.invoice[j].customer_id;
            params.proj_id = $scope.invoice[j].project_id;
            params.act_id = $scope.invoice[j].activity_id;
            break;
          case "P":
            params.cust_id = $scope.invoice[j].customer_id;
            params.proj_id = $scope.invoice[j].project_id;
            break;
          case "C":
          case "KU":
            params.cust_id = $scope.invoice[j].customer_id;
            break;
        }

        params.detailMode = $scope.detailMode;
        params.groupMode = $scope.groupMode;
        InvoiceIt.get(params, function (data) {
          //console.log(data[0]);
          $scope.invoiceIt = data[0].invoice;
          $scope.invoiceItDetails = data[0].invoice_detail;
          $scope.companyInfo = data[0].company[0];
          //$scope.invoice_number = data[0].invoice_number;
          //console.log('invoiceIt get' + $scope.invoiceIt.length);
          var promises = [];
          for (var k = 0; k < $scope.invoiceIt.length; k++) {
            var invParams = {};
            $scope.invoiceIt[k].companyInfo = $scope.companyInfo;
            $scope.invoiceIt[k].details = $scope.invoiceItDetails;
            invParams.detailMode = params.detailMode;
            $scope.invoiceIt[k].detailMode = invParams.detailMode;
            invParams.inputdate = moment()
              .startOf("month")
              .format("YYYY-MM-DD");
            invParams.customer_id = $scope.invoiceIt[k].customer_id;
            invParams.project_id = $scope.invoiceIt[k].project_id;
            invParams.activity_id = $scope.invoiceIt[k].activity_id;
            invParams.employee_id = $scope.invoiceIt[k].employee_id;
            invParams.statement = JSON.stringify($scope.invoiceIt[k]);
            invParams.amount = $scope.invoiceIt[k].pris;
            invParams.payment_term = $scope.invoiceIt[k].payment_term;

            var promise = InvoiceIt.insert(
              {
                cust_id: invParams.customer_id,
                from: $scope.momentStart.format("YYYY-MM-DD"),
                to: $scope.momentStop.format("YYYY-MM-DD"),
                groupMode: $scope.groupMode,
              },
              invParams,
              function (invData) {
                $scope.invoiceData = invData[0];
                invParams.invoice_number = invData.invoice_number;
                //console.log('invoiceIt.insert ' + invParams.invoice_number);
                //invoicePdf.viewInvoice(invParams);
              }
            );
            promises.push(promise.$promise);
          }
          $q.all(promises).then(function () {
            $scope.fetchInvoices();
          });
        });
      }
    }

    var len = $scope.invoice.length;
    while (len--) {
      if ($scope.invoice[len].invoiceit) {
        $scope.invoice.splice(len, 1);
      }
    }
  };

  $scope.fetch = function () {
    $scope.totAmm1 = 0;

    $scope.fetchInvoiceAble();
    $scope.fetchInvoices();
  };

  $scope.fetchInvoiceAble = function () {
    switch ($scope.groupMode) {
      case "K":
        $scope.header = [
          "Kund",
          "Projekt",
          "Aktivitet",
          "Namn",
          "Timmar",
          "Timpris",
          "Summa",
        ];
        break;
      case "A":
        $scope.header = [
          "Kund",
          "Projekt",
          "Aktivitet",
          "Timmar",
          "Timpris",
          "Summa",
        ];
        break;

      case "P":
        $scope.header = ["Kund", "Projekt", "Timmar", "Timpris", "Summa"];
        break;

      case "KU":
        $scope.header = ["Kund", "Timmar", "Timpris", "Summa"];
        break;

      default:
        break;
    }

    $scope.momentStart = moment($scope.formData.dateFrom);
    $scope.momentStop = moment($scope.formData.dateTo);

    Invoice.get(
      {
        subTotal: $scope.subTotal,
        sum: $scope.sumMode,
        custMode: $scope.custMode,
        per: $scope.timeMode,
        type: $scope.groupMode,
        from: $scope.momentStart.format("YYYY-MM-DD"),
        to: $scope.momentStop.format("YYYY-MM-DD"),
      },
      function (data) {
        $scope.invoice = data;
        var totAmount = 0;
        for (var j = 0; j < $scope.invoice.length; j++) {
          try {
            $scope.invoice[j].timpris =
              Math.round(
                ($scope.invoice[j].pris / $scope.invoice[j].sum) * 100
              ) / 100;
            $scope.invoice[j].timpris = $scope.invoice[
              j
            ].timpris.toLocaleString("sv-SE", { useGrouping: false });
          } catch (error) {
            $scope.invoice[j].timpris = null;
          }
          try {
            $scope.invoice[j].sum = $scope.invoice[j].sum.toLocaleString(
              "sv-SE",
              { useGrouping: false }
            );
          } catch (error) {
            $scope.invoice[j].sum = null;
          }
          try {
            $scope.invoice[j].pris = $scope.invoice[j].pris.toLocaleString(
              "sv-SE",
              { useGrouping: false }
            );
          } catch (error) {
            $scope.invoice[j].pris = null;
          }

          totAmount += Number($scope.invoice[j].pris);

          if (!$scope.invoice[j].pris) {
            $scope.invoice[j].timpris = null;
          }
          if ($scope.invoice[j].customername === null) {
            $scope.invoice[j].customername = "Tot";
          } else if (typeof $scope.invoice[j].project_name === "undefined") {
            $scope.invoice[j].project_name = null;
          } else if (typeof $scope.invoice[j].activity_name === "undefined") {
            $scope.invoice[j].activity_name = null;
          }
          $scope.invoice[j].invoiceit = false;
        }
        //console.log($scope.invoice);
        $scope.totAmm1 = totAmount;
      }
    );
  };

  $scope.selectOptions = {};

  $scope.fetchInvoices = function () {
    //spinnerService.show('invoiceReportSpinner1');
    $scope.totAmm2 = 0;

    $scope.header2 = [
      "Kund",
      "Projekt",
      "Datum",
      "Period start",
      "Period stop",
      "Summa",
    ];

    $scope.momentStart = moment($scope.formData.dateFrom);
    $scope.momentStop = moment($scope.formData.dateTo);

    var cust, proj;

    if (!$scope.selectOptions.customer) {
      cust = null;
    } else {
      cust = $scope.selectOptions.customer.id;
    }
    if (!$scope.selectOptions.project) {
      proj = null;
    } else {
      proj = $scope.selectOptions.project.id;
    }

    ReadInvoice.get(
      {
        cust: cust,
        proj: proj,
        from: $scope.momentStart.format("YYYY-MM-DD"),
        to: $scope.momentStop.format("YYYY-MM-DD"),
      },
      function (data) {
        //spinnerService.hide('invoiceReportSpinner1');
        $scope.invoiceList = data;
        var totAmount = 0;
        for (var j = 0; j < $scope.invoiceList.length; j++) {
          try {
            $scope.invoiceList[j].inputdate = $filter("date")(
              $scope.invoiceList[j].inputdate,
              "yyyy-MM-dd"
            );
            $scope.invoiceList[j].period_start = $filter("date")(
              $scope.invoiceList[j].period_start,
              "yyyy-MM-dd"
            );
            $scope.invoiceList[j].period_stop = $filter("date")(
              $scope.invoiceList[j].period_stop,
              "yyyy-MM-dd"
            );
          } catch (error) {
            $scope.invoiceList[j].inputdate = null;
            $scope.invoiceList[j].period_start = null;
            $scope.invoiceList[j].period_stop = null;
          }
          totAmount += $scope.invoiceList[j].amount;
        }
        $scope.totAmm2 = totAmount;
        //console.log($scope.invoiceList);
      }
    );
  };

  $scope.releaseTimerecords = function (inv, index) {
    //console.log(inv.statement_id + ':' + inv.invoice_number);
    InvoiceOp.release(
      {
        statement_id: inv.statement_id,
        invoice_number: inv.invoice_number,
      },
      {},
      function (data) {
        if (
          typeof data.result !== "undefined" &&
          data.result.affectedRows > 0
        ) {
          //console.log(data);
        } else {
          //console.log("ERRROROORRRR" + data);
        }
      }
    );
  };

  $scope.sendToErp = function (invoice, index, inv2) {
    let modalDefaults = {
      template: okModal,
      windowClass: "ok-modal-window",
    };

    let modalOptions = {
      closeButtonText: "Avbryt",
      actionButtonText: "OK",
      headerText: "Är du säker",
      bodyText: "Uppdatera fakturan?",
    };

    let errorModalDefaults = {
      template: errorModal,
      windowClass: "error-modal-window",
    };

    let errorModalOptions = {
      actionButtonText: "OK",
      headerText: "Fel",
    };
    var inv = $scope.invoiceList[index];
    //console.log(invoice);
    //console.log(inv2);
    //console.log(inv);
    //inv.statement.details.splice(index,0,{});
    var statement = JSON.parse(inv.statement);
    //console.log(statement);

    var erpInfo = {};

    erpInfo.CustomerNumber = statement.ext_customer_id
      ? statement.ext_customer_id
      : statement.customername;
    erpInfo.DocumentNumber = inv.invoice_number;
    erpInfo.InvoiceDate = inv.inputdate;
    erpInfo.OurReference = statement.ourreference;
    erpInfo.YourReference = statement.extreference;
    erpInfo.YourOrderNumber = statement.extprojectid;
    erpInfo.Remarks =
      "Fakturan avser tid mellan " + inv.period_start + " - " + inv.period_stop;
    erpInfo.Comments = statement.invoice_comment
      ? statement.invoice_comment
      : "";
    erpInfo.InvoiceRows = [];
    var info = {};
    for (var m = 0, l = statement.details.length; m < l; m++) {
      info.project_name = statement.details[m].external_project_name
        ? statement.details[m].external_project_name
        : statement.details[m].project_name;
      info.activity_name = statement.details[m].external_activity_name
        ? statement.details[m].external_activity_name
        : statement.details[m].activity_name;
      info.costcenter = statement.details[m].activity_costcenter
        ? statement.details[m].activity_costcenter
        : statement.details[m].costcenter;
      if (!info.project_name) {
        info.project_name = "";
      }
      if (!info.activity_name) {
        info.activity_name = "";
      }
      if (!info.costcenter) {
        info.costcenter = "";
      }

      erpInfo.InvoiceRows.push({
        ArticleNumber: 1,
        Description:
          info.project_name +
          " " +
          statement.details[m].name +
          " " +
          info.activity_name,
        DeliveredQuantity: statement.details[m].sum,
        Price: statement.details[m].price,
        CostCenter: info.costcenter,
      });
    }

    //console.log(erpInfo);

    if (inv.ext_statement_id === null) {
      Erp.save(
        { statement_id: inv.statement_id },
        erpInfo,
        function (success) {
          //console.log("Success");
          //console.log(success);
          inv.ext_statement_id = success.id;
        },
        function (error) {
          console.log("Error save");
          //console.log(error);
        }
      );
    } else {
      let ressa = Modal.showModal(modalDefaults, modalOptions);
      ressa.then(
        function (res) {
          console.log("bra");
          Erp.update(
            { id: inv.ext_statement_id },
            erpInfo,
            function (success) {
              console.log("Success");
              console.log(erpInfo);
              console.log(success);
            },
            function (error) {
              console.log("Error update");
              errorModalOptions.bodyText = error;
              Modal.showModal(errorModalDefaults, errorModalOptions);
              console.log(error);
              //console.log(erpInfo);
            }
          );
        },
        function (error) {
          console.log("dåligt");
        }
      );
    }
    /*
                 { "Invoice" : {
                 "AccountingMethod": "ACCURAL",
                 "CustomerNumber": "Cerner",
                 "Comments":"testa testa testa",
                 "Remarks": "Fakturan avser tid mellan 2017-04-01-2017-04-30",
                 "InvoiceDate": "2017-06-01",
                 "InvoiceType": "INVOICE",
                 "OurReference": "Johan Herbo",
                 "YourReference": "evan",
                 "YourOrderNumber": "2341234123412342",
                 "InvoiceRows": 
                 [
                 {
                 "ArticleNumber": 1,
                 "Description": "Cloud Connect Freddie Kruger Scrummaster",
                 "DeliveredQuantity": 145,
                 "Price": 616
                 }
                 ]}
                 }
                 */
  };

  $scope.edit = function (invoice, index) {
    var inv = $scope.invoiceList[index];

    //console.log("Edit " + inv.statement_id + ':' + inv.invoice_number);

    var editInv = angular.copy(inv);
    editInv.statement = JSON.parse(editInv.statement);

    if (typeof editInv.statement.org_statement === "undefined") {
      //console.log("backup org");
      editInv.statement.org_statement = angular.copy(editInv.statement);
    } else {
      //console.log("already edited");
    }

    for (let index = 0; index < editInv.statement.details.length; index++) {
      console.log(editInv.statement.details[index]);
      if (!editInv.statement.details[index].activity_costcenter) {
        editInv.statement.details[index].activity_costcenter =
          editInv.statement.details[index].costcenter;
      }
    }

    var removeDetail = function (index) {
      if (editInv.statement.details.length > 1) {
        editInv.statement.details.splice(index, 1);
      }
    };

    var addDetail = function (index) {
      editInv.statement.details.splice(index, 0, {});
    };

    var save = function () {
      //console.log('save');
    };

    var cancel = function () {
      //console.log('cancel');
    };

    var modalDefaults = {
      template: editModal,
      windowClass: "app-modal-window",
    };

    var modalOptions = {
      closeButtonText: "Avbryt",
      actionButtonText: "OK",
      headerText: "Editera faktura",
      inv: editInv,
      index: index,
      save: save,
      cancel: cancel,
      removeDetail: removeDetail,
      addDetail: addDetail,
    };

    var ress = Modal.showModal(modalDefaults, modalOptions);

    ress.then(
      function () {
        //console.log('bra');
        //console.log(editInv.statement.details);

        editInv.statement.pris = 0;
        for (var m = 0, l = editInv.statement.details.length; m < l; m++) {
          editInv.statement.pris += editInv.statement.details[m].pris;
        }

        editInv.amount = editInv.statement.pris;

        editInv.statement = JSON.stringify(editInv.statement);
        inv = editInv;
        $scope.invoiceList[index] = inv;
        $scope.updateInvoiceNo(inv);
      },
      function () {
        //console.log('dåligt');
      }
    );
  };

  $scope.remove = function (invoice, index) {
    //console.log(inv.statement_id + ':' + inv.invoice_number);
    var inv = invoice[index];
    InvoiceOp.get(
      {
        statement_id: inv.statement_id,
        invoice_number: inv.invoice_number,
      },
      {},
      function (data) {
        //console.log(data);
        invoice.splice(index, 1);
        $scope.fetchInvoiceAble();
        if (
          typeof data.result !== "undefined" &&
          data.result.affectedRows > 0
        ) {
          //console.log(data);
        } else {
          //console.log("ERRROROORRRR" + data);
        }
      }
    );
  };

  $scope.updateInvoiceNo = function (inv) {
    //console.log(inv.statement_id + ':' + inv.invoice_number);
    InvoiceOp.update(
      {
        statement_id: inv.statement_id,
        invoice_number: inv.invoice_number,
        amount: inv.amount,
        statement: inv.statement,
      },
      {},
      function (data) {
        //console.log(data);
        if (
          typeof data.result !== "undefined" &&
          data.result.affectedRows === 1
        ) {
          //console.log(data);
        } else {
          //console.log("ERRROROORRRR" + data);
        }
      }
    );
  };

  $scope.kredit = function (inv, index) {
    var invParams = {};
    angular.copy(inv, invParams);

    var statement = JSON.parse(invParams.statement);
    if (statement.statementType === "kredit") {
      return;
    }

    invParams.amount = -invParams.amount;
    statement.pris = -statement.pris;
    for (var j = 0, len = statement.details.length; j < len; j++) {
      statement.details[j].pris = -statement.details[j].pris;
      statement.details[j].price = -statement.details[j].price;
    }

    statement.statementType = "kredit";
    invParams.statementType = statement.statementType;
    invParams.statement = JSON.stringify(statement);

    InvoiceIt.insert(
      {
        cust_id: invParams.customer_id,
        from: $scope.momentStart.format("YYYY-MM-DD"),
        to: $scope.momentStop.format("YYYY-MM-DD"),
      },
      invParams,
      function (invData) {
        $scope.invoiceData = invData[0];
        invParams.invoice_number = invData.invoice_number;
        //console.log('invoiceIt.insert ' + invParams.invoice_number);
        //invoicePdf.viewInvoice(invParams);
        $scope.fetchInvoices();
      }
    );
  };
}
