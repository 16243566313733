angular
  .module("kitstajm")
  .controller(
    "ActivityCtrl",
    function ($scope, Activities, moment, Employees, Projectss, Customers) {
      $scope.activeMode = "J";
      $scope.whoMode = "A";

      $scope.activeChanged = () => {
        Activities.activities.status = $scope.activeMode;
        Activities.updateProjectActivities(
          Projectss.projects.selProj.project_id,
          $scope.activeMode
        );
      };

      $scope.employeesData = Employees;
      $scope.customersData = Customers;
      $scope.projectsData = Projectss;
      $scope.activitiesData = Activities;

      Activities.activityDropdownSettings = {
        smartButtonMaxItems: 1,
        styleActive: true,
        checkBoxes: false,
        alwaysOpened: false,
        displayProp: "activity_name",
        selectionLimit: 1,
        showCheckAll: false,
        showUncheckAll: false,
        closeOnSelect: true,
        scrollableHeight: "300px",
        scrollable: true,
        enableSearch: true,
        searchField: "activity_name",
      };

      Activities.activityEvents = {
        onItemSelect: function (item) {
          let selid = $scope.findIndexById(
            Activities.activities.data,
            "activity_id",
            item.activity_id
          );

          Activities.activities.selAct = Activities.activities.data[selid];
          Activities.actCopy(
            Activities.activities.selAct,
            Activities.activities.editAct
          );
          Activities.activities.index = selid;

          Activities.updateActivityEmployee(item.employee_id);
        },
        onItemDeselect: function (item) {
          console.log("Item deselected:", item);
        },
      };

      Activities.employeeEvents = {
        onItemSelect: function (item) {
          let selid = $scope.findIndexById(
            Employees.employees.all,
            "employee_id",
            item.employee_id
          );
        },
        onItemDeselect: function (item) {
          console.log("Item deselected:", item);
        },
      };

      $scope.cancelAct = function (arr) {
        if (typeof arr === "undefined") {
          return;
        }
        if (arr.isNew) {
          arr.isNew = false;
          arr.selAct = arr.prevSel;
        }
        delete arr.editAct;
        arr.editAct = {};
        $scope.actCopy(arr.selAct, arr.editAct);
      };

      $scope.moveAct = function (dir, arr) {
        if (typeof arr === "undefined") {
          return;
        }
        if (dir >= 1 && arr.index < arr.data.length - 1) {
          arr.selAct = arr.data[++arr.index];
          Activities.actCopy(arr.selAct, arr.editAct);
        } else if (dir < 1 && arr.index > 0) {
          arr.selAct = arr.data[--arr.index];
          Activities.actCopy(arr.selAct, arr.editAct);
        }
        arr.sAct[0] = arr.selAct;

        Employees.employees.index = $scope.findIndexById(
          Employees.employees.data,
          "employee_id",
          arr.selAct.employee_id
        );

        Employees.employees.selEmp =
          Employees.employees.data[Employees.employees.index];

        Employees.employees.sEmp[0] = Employees.employees.selEmp;

        Employees.employees.editEmp = {};
        Employees.empCopy(
          Employees.employees.selEmp,
          Employees.employees.editEmp
        );

        Activities.updateActivityEmployee(arr.selAct.employee_id);
      };

      $scope.saveAct = function (arr) {
        //console.log(arr);

        arr.editAct.mstart_date = moment(arr.editAct.start_date).format(
          "YYYY-MM-DD"
        );
        arr.editAct.mstop_date = moment(arr.editAct.stop_date).format(
          "YYYY-MM-DD"
        );

        if (typeof arr === "undefined") {
          return;
        }

        if (typeof arr.editAct.activity_id !== "undefined") {
          //console.log(arr);
          Activities.update(
            { id: arr.editAct.activity_id },
            arr.editAct,
            function (result) {
              // Save back to array obj
              $scope.actCopy(arr.editAct, arr.selAct);
            },
            function (error) {
              //console.log('err update', error);
            }
          );
        } else {
          Activities.insert(
            {},
            arr.editAct,
            function (result) {
              // Save back to array obj
              arr.isNew = false;
              // id from DB
              arr.editAct.activity_id = result.insertId;
              // add to all activities
              var x = $scope.activities.data.push(arr.editAct) - 1;
              Activities.activities.selAct = $scope.activities.data[x];
              // add employee info
              Activities.activities.selAct.employee = $scope.employees.selEmp;
              // add to selected proj activities list
              if (typeof arr.activities === "undefined") {
                //first activity
                arr.activities = [];
              }
              var y = arr.activities.push($scope.activities.data[x]) - 1;
              arr.actIndex = y;
              // add to employees activities
              $scope.employees.selEmp.activities.push($scope.activities.selAct);
              arr.selAct = arr.activities[arr.actIndex];
              // remove ref to new
              arr.editAct = {};
              // copy back data
              $scope.actCopy(arr.selAct, arr.editAct);
            },
            function (error) {
              //console.log('err update', error);
            }
          );
        }
      };

      $scope.addAct = function (arr) {
        if (typeof arr === "undefined") {
          return;
        }

        if (!arr.isNew) {
          //arr.selAct = null;
          $scope.employees.selEmp = null;
          arr.prevSel = arr.selAct;
          arr.editAct = {};
          arr.selAct = arr.editAct;
          arr.editAct.project_id = arr.project_id;
          arr.isNew = true;
        }
      };

      $scope.delAct = function (arr) {
        if (typeof arr === "undefined") {
          return;
        }

        if (typeof arr.editAct.activity_id !== "undefined") {
          Activities.delete(
            { id: arr.editAct.activity_id },
            arr.editAct,
            function (result) {
              //console.log(result);
            },
            function (error) {
              //console.log('err update', error);
            }
          );
        } else {
          //console.log('no id');
        }
      };
    }
  );
