angular.module("kitstajm").controller(
  "AdminCtrl",
  function (
    $scope,
    Activities,

    Customers,
    Projectss,
    Employees,
    CustProjects,
    moment,
    Utils
  ) {
    $scope.employeesData = Employees;
    $scope.customersData = Customers;
    $scope.projectsData = Projectss;
    $scope.activitiesData = Activities;

    $scope.employeesData.getEmployees().$promise.then(() => {
      $scope.employeesData.filterEmployee();
      $scope.customersData.getCustomers().$promise.then(() => {
        $scope.projectsData.getProjects().$promise.then(() => {
          $scope.projectsData.updateCustProjects(
            $scope.customersData.customers.selCust.customer_id
          );
          $scope.activitiesData.getActivities().$promise.then(() => {
            $scope.activitiesData.updateProjectActivities(
              $scope.projectsData.projects.selProj.project_id,
              null
            );
          });
        });
      });
    });

    $scope.findIndexById = Utils.findIndexById;

    $scope.menu = [
      { title: "Anställda", active: true, route: "emp" },
      { title: "Kunder", active: false, route: "cust" },
      { title: "Projekt", active: false, route: "proj" },
      { title: "Aktiviteter", active: false, route: "act" },
    ];
  }
);
