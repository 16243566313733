ProjectreportCtrl.$inject = [
  "$scope",
  "$filter",
  "ProjReport",
  "CustProjects",
  "moment",
];

export default function ProjectreportCtrl(
  $scope,
  $filter,
  ProjReport,
  CustProjects,
  moment
) {
  $scope.formData = {};
  $scope.formData.dateTo = null;
  $scope.formData.dateFrom = null;
  $scope.opened1 = false;
  $scope.opened2 = false;

  //Datepicker
  $scope.dateOptions = {
    "year-format": "'yy'",
    "show-weeks": true,
    "starting-day": 1,
  };

  $scope.message = "Projektrapport";

  $scope.header = ["ID", "Namn", "Summa"];

  $scope.custMode = { external: true, internal: true, absense: true };
  $scope.groupMode = "K";
  $scope.timeMode = "A";
  $scope.sumMode = "A";

  if (!$scope.formData.dateFrom) {
    $scope.momentStart = moment(
      moment().startOf("day").startOf("month").format("YYYY-MM-DD")
    );
    $scope.formData.dateFrom = $scope.momentStart.toDate();
  }

  if (!$scope.formData.dateTo) {
    $scope.momentStop = moment($scope.momentStart)
      .add(1, "month")
      .subtract(1, "days");
    $scope.formData.dateTo = $scope.momentStop.toDate();
  }

  $scope.selectOptions = {};
  $scope.fetchCustProjects = function () {
    CustProjects.get({}, function (data) {
      $scope.selectOptions.customers = data;
      //console.log($scope.selectOptions.customers);
    });
  };

  $scope.fetchCustProjects();

  $scope.getHeader = function () {
    return $scope.header;
  };

  $scope.koko = function () {
    //console.log($scope.invoice);
    return $scope.exportData;
  };

  $scope.updatePeriod = function (dir) {
    $scope.momentStart = moment($scope.formData.dateFrom, "YYYY-MM-DD");
    $scope.momentStop = moment($scope.formData.dateTo, "YYYY-MM-DD");
    if (dir === -1) {
      $scope.momentStart.startOf("month").subtract(1, "months");
      $scope.momentStop.startOf("month").subtract(1, "days");
    } else {
      $scope.momentStart.startOf("month").add(1, "months");
      $scope.momentStop.startOf("month").add(2, "months").subtract(1, "days");
    }
    $scope.formData.dateFrom = $scope.momentStart.toDate();
    $scope.formData.dateTo = $scope.momentStop.toDate();
  };

  $scope.kaka = function (head, inv, index, row) {
    if (!$scope.exportData[row]) {
      $scope.exportData[row] = [];
    }
    switch (head) {
      case "Kund":
        $scope.exportData[row][index] = inv.customername;
        break;
      case "Projekt":
        $scope.exportData[row][index] = inv.project_name;
        break;
      case "Aktivitet":
        $scope.exportData[row][index] = inv.activity_name;
        break;
      case "Namn":
        $scope.exportData[row][index] = inv.name;
        break;
      case "Timmar":
        $scope.exportData[row][index] = inv.time.toLocaleString("sv-SE", {
          useGrouping: false,
        });
        break;
      case "Datum":
        $scope.exportData[row][index] = inv.reporteddate;
        break;
      case "Summa":
        $scope.exportData[row][index] = inv.sum.toLocaleString("sv-SE", {
          useGrouping: false,
        });
        break;
      case "ID":
        $scope.exportData[row][index] = inv.employee_id;
        break;
    }
    return $scope.exportData[row][index];
  };

  $scope.fetchSalary = function () {
    $scope.exportData = [];
    $scope.invoice = [];

    //spinnerService.show('projectReportSpinner1');
    switch ($scope.groupMode) {
      case "KU":
        $scope.header = ["Namn", "Kund", "Summa"];
        break;
      case "A":
        $scope.header = ["Namn", "Kund", "Projekt", "Aktivitet", "Summa"];
        break;

      case "P":
        $scope.header = ["Namn", "Kund", "Projekt", "Summa"];
        break;

      case "K":
        $scope.header = [
          "Namn",
          "Datum",
          "Kund",
          "Projekt",
          "Aktivitet",
          "Timmar",
        ];
        break;

      case "T":
        $scope.header = ["Namn", "Summa"];
        break;

      default:
        break;
    }

    $scope.momentStart = moment($scope.formData.dateFrom);
    $scope.momentStop = moment($scope.formData.dateTo);

    var cust, proj, act;

    if (!$scope.selectOptions.customer) {
      cust = null;
    } else {
      cust = $scope.selectOptions.customer.id;
    }
    if (!$scope.selectOptions.project) {
      proj = null;
    } else {
      proj = $scope.selectOptions.project.id;
    }
    if (!$scope.selectOptions.activity_name) {
      act = null;
    } else {
      act = $scope.selectOptions.activity_name.id;
    }

    ProjReport.get(
      {
        custMode: $scope.custMode,
        cust: cust,
        proj: proj,
        act: act,
        id: 0,
        per: $scope.timeMode,
        type: $scope.groupMode,
        from: $scope.momentStart.format("YYYY-MM-DD"),
        to: $scope.momentStop.format("YYYY-MM-DD"),
      },
      function (data) {
        //spinnerService.hide('projectReportSpinner1');
        $scope.invoice = data;
        for (var j = 0; j < $scope.invoice.length; j++) {
          try {
            $scope.invoice[j].reporteddate = $filter("date")(
              $scope.invoice[j].reporteddate,
              "yyyy-MM-dd"
            );
          } catch (error) {
            $scope.invoice[j].reporteddate = null;
          }
        }
        //console.log($scope.invoice);
      }
    );
  };
}
