angular
  .module("kitstajm")
  .controller(
    "CustomerCtrl",
    function ($scope, Customers, Employees, Projectss, Activities) {
      $scope.activeMode = "A";

      $scope.activeChanged = () => {
        Customers.getCustomers($scope.activeMode);
      };

      $scope.employeesData = Employees;
      $scope.customersData = Customers;
      $scope.projectsData = Projectss;
      $scope.activitiesData = Activities;

      Customers.customerDropdownSettings = {
        smartButtonMaxItems: 1,
        styleActive: true,
        checkBoxes: false,
        alwaysOpened: false,
        displayProp: "customername",
        selectionLimit: 1,
        showCheckAll: false,
        showUncheckAll: false,
        closeOnSelect: true,
        scrollableHeight: "300px",
        scrollable: true,
        enableSearch: true,
        searchField: "customername",
      };

      Customers.customerEvents = {
        onItemSelect: function (item) {
          let selid = $scope.findIndexById(
            Customers.customers.data,
            "customer_id",
            item.customer_id
          );

          Customers.customers.selCust = Customers.customers.data[selid];
          Customers.custCopy(
            Customers.customers.selCust,
            Customers.customers.editCust
          );
          Customers.customers.index = selid;

          Projectss.updateCustProjects(Customers.customers.selCust.customer_id);
          Activities.updateProjectActivities(
            Projectss.projects.selProj.project_id,
            null
          );
        },
        onItemDeselect: function (item) {
          console.log("Item deselected:", item);
        },
      };

      $scope.saveCust = function (arr) {
        if (typeof arr === "undefined") {
          return;
        }
        if (typeof arr.editCust.customer_id !== "undefined") {
          Customers.update(
            { id: arr.editCust.customer_id },
            arr.editCust,
            function (result) {
              // Save back to array obj
              $scope.custCopy(arr.editCust, arr.selCust);
            },
            function (error) {
              //console.log('err update', error);
            }
          );
        } else {
          Customers.insert(
            {},
            arr.editCust,
            function (result) {
              // Save back to array obj
              arr.isNew = false;
              arr.editCust.customer_id = result.insertId;
              var x = $scope.customers.data.push(arr.editCust) - 1;
              arr.selCust = arr.editCust;
              arr.editCust = {};
              $scope.custCopy(arr.selCust, arr.editCust);
            },
            function (error) {
              //console.log('err update', error);
            }
          );
        }
      };

      $scope.moveCust = function (dir, arr) {
        if (typeof arr === "undefined") {
          return;
        }
        if (dir >= 1 && arr.index < arr.data.length - 1) {
          arr.selCust = arr.data[++arr.index];
          Customers.custCopy(arr.selCust, arr.editCust);
        } else if (dir < 1 && arr.index > 0) {
          arr.selCust = arr.data[--arr.index];
          Customers.custCopy(arr.selCust, arr.editCust);
        }
        arr.sCust[0] = arr.selCust;
        $scope.projectsData.updateCustProjects(arr.selCust.customer_id);
      };

      $scope.cancelCust = function (arr) {
        if (typeof arr === "undefined") {
          return;
        }
        if (arr.isNew) {
          arr.isNew = false;
          arr.selCust = arr.prevSel;
        }
        delete arr.editCust;
        arr.editCust = {};
        $scope.custCopy(arr.selCust, arr.editCust);
      };

      $scope.addCust = function (arr) {
        if (typeof arr === "undefined") {
          return;
        }
        if (!arr.isNew) {
          //$scope.projects.selProj = null;
          arr.prevSel = arr.selCust;
          arr.editCust = {};
          arr.selCust = arr.editCust;
          arr.isNew = true;
        }
      };
    }
  );
