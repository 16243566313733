import editModal from "./editModal.html";

ProjectSummaryReportCtrl.$inject = [
  "$scope",
  "$filter",
  "ProjSummary",
  "CustProjects",
  "Activity",
  "Modal",
  "moment",
];

export default function ProjectSummaryReportCtrl(
  $scope,
  $filter,
  ProjSummary,
  CustProjects,
  Activity,
  Modal,
  moment
) {
  $scope.formData = {};
  $scope.formData.dateTo = null;
  $scope.formData.dateFrom = null;
  $scope.opened1 = false;
  $scope.opened2 = false;

  //Datepicker
  $scope.dateOptions = {
    "year-format": "'yy'",
    "show-weeks": true,
    "starting-day": 1,
  };

  $scope.message = "Projektrapport";

  $scope.header = ["ID", "Namn", "Summa"];

  $scope.custMode = { external: true, internal: false, absense: false };
  $scope.groupMode = "KU";
  $scope.timeMode = "A";
  $scope.sumMode = "A";

  if (!$scope.formData.dateFrom) {
    $scope.momentStart = moment(
      moment().startOf("day").startOf("month").format("YYYY-MM-DD")
    );
    $scope.formData.dateFrom = $scope.momentStart.toDate();
  }

  if (!$scope.formData.dateTo) {
    $scope.momentStop = moment($scope.momentStart)
      .add(1, "month")
      .subtract(1, "days");
    $scope.formData.dateTo = $scope.momentStop.toDate();
  }

  $scope.selectOptions = {};
  $scope.fetchCustProjects = function () {
    CustProjects.get({}, function (data) {
      $scope.selectOptions.customers = data;
      //console.log($scope.selectOptions.customers);
    });
  };

  $scope.fetchCustProjects();

  $scope.getHeader = function () {
    return $scope.header;
  };

  $scope.koko = function () {
    //console.log($scope.invoice);
    return $scope.exportData;
  };

  $scope.updatePeriod = function (dir) {
    $scope.momentStart = moment($scope.formData.dateFrom, "YYYY-MM-DD");
    $scope.momentStop = moment($scope.formData.dateTo, "YYYY-MM-DD");
    if (dir === -1) {
      $scope.momentStart.startOf("month").subtract(1, "months");
      $scope.momentStop.startOf("month").subtract(1, "days");
    } else {
      $scope.momentStart.startOf("month").add(1, "months");
      $scope.momentStop.startOf("month").add(2, "months").subtract(1, "days");
    }
    $scope.formData.dateFrom = $scope.momentStart.toDate();
    $scope.formData.dateTo = $scope.momentStop.toDate();
  };

  $scope.kaka = function (head, inv, index, row) {
    if (!$scope.exportData[row]) {
      $scope.exportData[row] = [];
    }
    switch (head) {
      case "Kund":
        $scope.exportData[row][index] = inv.customername;
        break;
      case "Projekt":
        $scope.exportData[row][index] = inv.project_name;
        break;
      case "Aktivitet":
        $scope.exportData[row][index] = inv.activity_name;
        break;
      case "Slutkund":
        $scope.exportData[row][index] = inv.endcustomer;
        break;
      case "Ext projekt":
        $scope.exportData[row][index] = inv.external_project_name;
        break;
      case "Ext aktivitet":
        $scope.exportData[row][index] = inv.external_activity_name;
        break;
      case "Namn":
        $scope.exportData[row][index] =
          inv.name + (inv.contractor === "J" ? " (underkonsult)" : "");
        break;
      case "Start datum":
        $scope.exportData[row][index] = inv.start_date;
        break;
      case "Slut datum":
        $scope.exportData[row][index] = inv.stop_date;
        break;
      case "Pris":
        $scope.exportData[row][index] = inv.price
          ? inv.price.toLocaleString("sv-SE", { useGrouping: false })
          : null;
        break;
      case "ID":
        $scope.exportData[row][index] = inv.employee_id;
        break;
    }
    return $scope.exportData[row][index];
  };

  $scope.fetchSummary = function () {
    $scope.exportData = [];
    $scope.invoice = [];

    switch ($scope.groupMode) {
      case "KU":
        $scope.header = [
          "Namn",
          "Kund",
          "Slutkund",
          "Projekt",
          "Ext projekt",
          "Aktivitet",
          "Ext aktivitet",
          "Start datum",
          "Slut datum",
          "Pris",
        ];
        break;
      case "A":
        break;

      case "P":
        break;

      case "K":
        $scope.header = [
          "Namn",
          "Kund",
          "Slutkund",
          "Projekt",
          "Ext projekt",
          "Aktivitet",
          "Ext aktivitet",
          "Start datum",
          "Slut datum",
          "Pris",
        ];
        break;

      case "T":
        break;

      default:
        break;
    }

    $scope.momentStart = moment($scope.formData.dateFrom);
    $scope.momentStop = moment($scope.formData.dateTo);

    var cust, proj, act;

    if (!$scope.selectOptions.customer) {
      cust = null;
    } else {
      cust = $scope.selectOptions.customer.id;
    }
    if (!$scope.selectOptions.project) {
      proj = null;
    } else {
      proj = $scope.selectOptions.project.id;
    }
    if (!$scope.selectOptions.activity_name) {
      act = null;
    } else {
      act = $scope.selectOptions.activity_name.id;
    }

    ProjSummary.get(
      {
        custMode: $scope.custMode,
        cust: cust,
        proj: proj,
        act: act,
        per: $scope.timeMode,
        type: $scope.groupMode,
        from: $scope.momentStart.format("YYYY-MM-DD"),
        to: $scope.momentStop.format("YYYY-MM-DD"),
      },
      function (data) {
        //spinnerService.hide('projectSummaryReportSpinner1');
        $scope.invoice = data;
        for (var j = 0; j < $scope.invoice.length; j++) {
          try {
            $scope.invoice[j].start_date = $filter("date")(
              $scope.invoice[j].start_date,
              "yyyy-MM-dd"
            );
          } catch (error) {
            $scope.invoice[j].start_date = null;
          }
          try {
            $scope.invoice[j].stop_date = $filter("date")(
              $scope.invoice[j].stop_date,
              "yyyy-MM-dd"
            );
          } catch (error) {
            $scope.invoice[j].stop_date = null;
          }
        }
        //console.log($scope.invoice);
      }
    );
  };

  $scope.newPrice = function (row) {
    //console.log(row);

    var save = function () {
      //console.log('save');
    };

    var cancel = function () {
      //console.log('cancel');
    };

    var modalDefaults = {
      template: editModal,
      windowClass: "app-modal-window",
    };

    var modalOptions = {
      closeButtonText: "Avbryt",
      actionButtonText: "OK",
      headerText: "Nytt pris",
      activity: row,
      save: save,
      cancel: cancel,
    };

    var ress = Modal.showModal(modalDefaults, modalOptions);

    ress.then(
      function () {
        //console.log("ok");
        //console.log(modalOptions);
        var start_date_less_one = moment(
          modalOptions.activity.new_start_date
        ).subtract(1, "days");
        Activity.update(
          {
            id: row.activity_id,
            new_price: modalOptions.activity.new_price,
            start_date_less_one: start_date_less_one.format("YYYY-MM-DD"),
            start_date: modalOptions.activity.new_start_date,
          },
          function (success) {
            //console.log(success);
            $scope.fetchSummary();
          },
          function (error) {
            //console.log(error);
          }
        );
      },
      function () {
        //console.log("NOT ok");
      }
    );
  };
}
