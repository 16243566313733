angular
  .module("kitstajm")
  .controller(
    "EmployeeCtrl",
    function ($scope, Employees, Activities, Customers, Projectss) {
      $scope.employeesData = Employees;
      $scope.customersData = Customers;
      $scope.projectsData = Projectss;
      $scope.activitiesData = Activities;

      $scope.activeMode = "A";
      $scope.whoMode = "A";

      $scope.activeChanged = () => {
        Employees.getEmployees($scope.activeMode, $scope.whoMode);
      };

      Employees.employeeDropdownSettings = {
        smartButtonMaxItems: 1,
        styleActive: true,
        checkBoxes: false,
        alwaysOpened: false,
        displayProp: "name",
        selectionLimit: 1,
        showCheckAll: false,
        showUncheckAll: false,
        closeOnSelect: true,
        scrollableHeight: "300px",
        scrollable: true,
        enableSearch: true,
        searchField: "name",
      };

      Employees.employeeEvents = {
        onItemSelect: function (item) {
          let selid = $scope.findIndexById(
            Employees.employees.data,
            "employee_id",
            item.employee_id
          );

          Employees.employees.selEmp = Employees.employees.data[selid];
          Employees.empCopy(
            Employees.employees.selEmp,
            Employees.employees.editEmp
          );
          Employees.employees.index = selid;
        },
        onItemDeselect: function (item) {
          console.log("Item deselected:", item);
        },
      };

      $scope.roller = { a: "admin", b: "styrelse", s: "sälj", k: "konsult" };

      $scope.active = true;
      $scope.uk = false;
      $scope.inactive = false;

      function findObjectById(array, id) {
        return array.find(function (element) {
          return element.id === id;
        });
      }

      $scope.saveEmp = function (arr) {
        if (typeof arr === "undefined") {
          return;
        }

        if (typeof arr.editEmp.employee_id !== "undefined") {
          Employees.update(
            { id: arr.editEmp.employee_id },
            arr.editEmp,
            function (result) {
              // Save back to array obj
              $scope.empCopy($scope.employees.editEmp, $scope.employees.selEmp);
            },
            function (error) {
              //console.log('err update', error);
            }
          );
        } else {
          Employees.insert(
            {},
            arr.editEmp,
            function (result) {
              // Save back to array obj
              arr.isNew = false;
              arr.editEmp.employee_id = result.insertId;
              var x = $scope.employees.data.push(arr.editEmp) - 1;
              arr.selEmp = arr.editEmp;
              arr.editEmp = {};
              $scope.empCopy($scope.employees.selEmp, $scope.employees.editEmp);
            },
            function (error) {
              //console.log('err update', error);
            }
          );
        }
      };

      $scope.moveEmp = function (dir, arr) {
        if (typeof arr === "undefined") {
          return;
        }
        if (dir >= 1 && arr.index + dir < arr.data.length) {
          arr.selEmp = arr.data[++arr.index];
          Employees.empCopy(arr.selEmp, arr.editEmp);
        } else if (dir < 1 && arr.index > 0) {
          arr.selEmp = arr.data[--arr.index];
          Employees.empCopy(arr.selEmp, arr.editEmp);
        }
        arr.sEmp[0] = arr.selEmp;
      };

      $scope.cancelEmp = function (arr) {
        if (typeof arr === "undefined") {
          return;
        }
        if (arr.isNew) {
          arr.isNew = false;
          arr.selEmp = arr.prevSel;
        }

        delete arr.editEmp;
        arr.editEmp = {};
        $scope.empCopy(arr.selEmp, arr.editEmp);
      };

      $scope.addEmp = function (arr) {
        if (typeof arr === "undefined") {
          return;
        }
        if (!arr.isNew) {
          //$scope.projects.selProj = null;
          arr.prevSel = arr.selEmp;
          arr.editEmp = {};
          arr.selEmp = arr.editEmp;
          arr.isNew = true;
        }
      };
    }
  );
