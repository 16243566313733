angular
  .module("kitstajm")
  .factory("Utils", function () {
    function findIndexById(array, name, id) {
      return array.findIndex(function (element) {
        return element[name] === id;
      });
    }
    function findElementById(array, name, id) {
      return array.find(function (element) {
        return element[name] === id;
      });
    }
    return {
      findIndexById: findIndexById,
      findElementById: findElementById,
    };
  })
  .factory("Activities", function ($resource, moment, Employees, Utils) {
    let activities = { AsEmp: [], status: "A" };

    const resource = $resource(
      "api/activities/:id",
      { id: "@id" },
      {
        get: { method: "GET", isArray: true },
        update: { method: "PUT" },
        insert: { method: "POST" },
        delete: { method: "DELETE" },
      }
    );

    const getActivities = () => {
      return resource.get(
        {},
        function (result) {
          result.forEach(function (activity) {
            activity.start_date = moment(
              new Date(activity.start_date)
            ).toDate();
            if (activity.stop_date !== null) {
              activity.stop_date = moment(
                new Date(activity.stop_date)
              ).toDate();
            }
          });
          activities.all = result;
          updateProjectActivities(null, null);
        },
        function (error) {
          console.log(error);
        }
      );
    };

    const updateActivityEmployee = (employee_id) => {
      let Eselid = Utils.findIndexById(
        Employees.employees.all,
        "employee_id",
        employee_id
      );
      activities.AsEmp[0] = Employees.employees.all[Eselid];
    };

    const updateProjectActivities = (project_id) => {
      console.log(activities.status);

      let active = true;
      let inactive = true;

      switch (activities.status) {
        case "A":
          active = true;
          inactive = true;
          break;
        case "J":
          active = true;
          inactive = false;
          break;
        case "I":
          active = false;
          inactive = true;
          break;
        default:
          active = true;
          inactive = true;
          break;
      }

      activities.data = activities.all.filter((activity) => {
        if (project_id === null || activity.project_id === project_id)
          return true;
      });

      activities.data = activities.data.filter((activity) => {
        console.log(activity);
        if (active) {
          if (activity.stop_date == null) return true;
        }
        if (inactive) {
          if (activity.stop_date != null) return true;
        }
      });

      if (activities.data.length == 0) {
        activities.editAct = {};
      } else {
        activities.index = 0;
        activities.selAct = activities.data[activities.index];
        activities.sAct = [activities.selAct];

        activities.editAct = {};
        actCopy(activities.selAct, activities.editAct);

        updateActivityEmployee(activities.selAct.employee_id);
      }
    };

    let actCopy = function (src, dst) {
      var prop = [
        "activity_name",
        "activity_id",
        "currency_id",
        "employee_id",
        "input_date",
        "invoice_lvl",
        "price",
        "project_id",
        "start_date",
        "stop_date",
        "unit",
      ];
      prop.forEach(function (name) {
        dst[name] = src[name];
      });
    };

    return {
      activities: activities,
      getActivities: getActivities,
      updateProjectActivities: updateProjectActivities,
      updateActivityEmployee: updateActivityEmployee,
      actCopy: actCopy,
    };
  })
  .factory("Customers", function ($resource) {
    let customers = { sCust: [] };

    let resource = $resource(
      "api/customers/:id",
      { id: "@id" },
      {
        get: { method: "GET", isArray: true },
        update: { method: "PUT" },
        insert: { method: "POST" },
      }
    );

    const getCustomers = () => {
      return resource.get({}, function (data) {
        customers.all = data;
        filterCustomers();
      });
    };

    const filterCustomers = () => {
      customers.data = customers.all.filter((value) => {
        return true;
      });

      customers.index = 0;
      customers.selCust = customers.data[customers.index];
      customers.sCust[0] = customers.selCust;

      customers.editCust = {};
      custCopy(customers.selCust, customers.editCust);
    };

    const custCopy = function (src, dst) {
      var prop = [
        "addinfo",
        "address1",
        "address2",
        "city",
        "country",
        "customer_id",
        "customername",
        "inputdate",
        "invoice_lvl",
        "legalname",
        "vatno",
        "zipcode",
      ];
      prop.forEach(function (name) {
        dst[name] = src[name];
      });
    };
    return {
      customers: customers,
      getCustomers: getCustomers,
      filterCustomers: filterCustomers,
      custCopy: custCopy,
    };
  })
  .factory("Employees", function ($resource, moment) {
    let employees = { sEmp: [] };

    let resource = $resource(
      "api/employees/:id",
      { id: "@id" },
      {
        get: { method: "GET", isArray: true },
        update: { method: "PUT" },
        insert: { method: "POST" },
      }
    );

    const empCopy = function (src, dst) {
      var prop = [
        "admin",
        "employee_number",
        "contractor",
        "employee_id",
        "loginname",
        "name",
        "passwd",
        "start_date",
        "stop_date",
      ];
      prop.forEach(function (name) {
        dst[name] = src[name];
      });
    };

    const getEmployees = (status, anst) => {
      return resource.get({}, function (result) {
        result.forEach((employee) => {
          employee.start_date = moment(new Date(employee.start_date)).toDate();
          if (employee.stop_date !== null) {
            employee.stop_date = moment(new Date(employee.stop_date)).toDate();
          }
        });
        employees.all = result;

        filterEmployee(status, anst);
      });
    };
    const filterEmployee = (status, anst) => {
      switch (status) {
        case "A":
          employees.active = true;
          employees.inactive = true;
          break;
        case "J":
          employees.active = true;
          employees.inactive = false;
          break;
        case "I":
          employees.active = false;
          employees.inactive = true;
          break;
        default:
          employees.active = true;
          employees.inactive = true;
          break;
      }

      switch (anst) {
        case "A":
          employees.kits = true;
          employees.uk = true;
          break;
        case "K":
          employees.kits = true;
          employees.uk = false;
          break;
        case "U":
          employees.kits = false;
          employees.uk = true;
          break;
        default:
          employees.kits = true;
          employees.uk = true;
          break;
      }

      employees.data = employees.all.filter((value) => {
        if (employees.active) {
          if (value.stop_date == null) return true;
        }
        if (employees.inactive) {
          if (value.stop_date != null) return true;
        }
      });

      employees.data = employees.data.filter((value) => {
        if (employees.kits) {
          if (value.employee_number != null) return true;
        }
        if (employees.uk) {
          if (value.employee_number == null) return true;
        }
      });

      employees.index = 0;
      employees.selEmp = employees.data[employees.index];
      employees.sEmp[0] = employees.selEmp;

      employees.editEmp = {};
      empCopy(employees.selEmp, employees.editEmp);
    };

    return {
      employees: employees,
      getEmployees: getEmployees,
      filterEmployee: filterEmployee,
      empCopy: empCopy,
    };
  })
  .factory("Projectss", function ($resource) {
    let projects = { sProj: [] };

    let resource = $resource(
      "api/projectss/:id",
      { id: "@id" },
      {
        get: { method: "GET", isArray: true },
        update: { method: "PUT" },
        insert: { method: "POST" },
      }
    );

    const getProjects = () => {
      return resource.get({}, function (data) {
        projects.all = data;
        updateCustProjects();
      });
    };

    const updateCustProjects = (customer_id) => {
      projects.data = projects.all.filter((value) => {
        if (customer_id == undefined || value.customer_id === customer_id)
          return true;
      });
      projects.index = 0;
      projects.selProj = projects.data[projects.index];
      projects.sProj[0] = projects.selProj;
      projects.editProj = {};
      projCopy(projects.selProj, projects.editProj);
    };

    const projCopy = function (src, dst) {
      var prop = [
        "addinfo",
        "customer",
        "customer_id",
        "endcustomer",
        "extprojectid",
        "extreference",
        "inputdate",
        "invoice_lvl",
        "ourreference",
        "project_id",
        "project_name",
      ];
      prop.forEach(function (name) {
        dst[name] = src[name];
      });
    };

    return {
      projects: projects,
      getProjects: getProjects,
      updateCustProjects: updateCustProjects,
      projCopy: projCopy,
    };
  });
