import editModal from "./editModal.html";

InvoicereportsCtrl.$inject = [
  "$scope",
  "$filter",
  "ReadInvoice",
  "CustProjects",
  "InvoiceOp",
  "InvoiceIt",
  "Modal",
  "moment",
  "Pdf",
  "fileSaver",
  "CONSTANTS",
];

export default function InvoicereportsCtrl(
  $scope,
  $filter,
  ReadInvoice,
  CustProjects,
  InvoiceOp,
  InvoiceIt,
  Modal,
  moment,
  Pdf,
  fileSaver,
  CONSTANTS
) {
  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  function getBlob(statement) {
    Pdf.save({ id: 1, statement: statement }, function (data) {
      var base64doc = data.result;
      return b64toBlob(base64doc, "application/pdf", 512);
    });
  }

  $scope.viewPdf = function (statement) {
    Pdf.save({ id: 1 }, { statement: statement }, function (data) {
      var base64doc = data.result;
      var blob = b64toBlob(base64doc, "application/pdf", 512);
      var win = window.open("", "_blank");
      var urlCreator = URL || webkitURL;
      var pdfUrl = urlCreator.createObjectURL(blob);
      win.location.href = pdfUrl;
    });
  };

  $scope.downloadPdf = function (statement) {
    var st = JSON.parse(statement.statement);
    var statementType = st.statementType === "kredit" ? "kreditfaktura " : "";
    var name =
      CONSTANTS.company +
      " " +
      statementType +
      statement.invoice_number +
      " " +
      statement.customername +
      ".pdf";

    Pdf.save({ id: 1 }, { statement: statement }, function (data) {
      var base64doc = data.result;
      var blob = b64toBlob(base64doc, "application/pdf", 512);
      fileSaver.saveAs(blob, name);
    });
  };

  $scope.formData = {};
  $scope.formData.dateTo = null;
  $scope.formData.dateFrom = null;
  $scope.opened1 = false;
  $scope.opened2 = false;

  //Datepicker
  $scope.dateOptions = {
    "year-format": "'yy'",
    "show-weeks": true,
    "starting-day": 1,
  };

  $scope.message = "Faktura rapport";

  $scope.header = ["ID", "Summa"];

  if (!$scope.formData.dateFrom) {
    if (moment().date() > 15) {
      $scope.momentStart = moment(
        moment().startOf("day").startOf("month").format("YYYY-MM-DD")
      );
    } else {
      $scope.momentStart = moment(
        moment()
          .startOf("day")
          .startOf("month")
          .add(-1, "month")
          .format("YYYY-MM-DD")
      );
    }
    $scope.formData.dateFrom = $scope.momentStart.toDate();
  }

  if (!$scope.formData.dateTo) {
    $scope.momentStop = moment($scope.momentStart)
      .add(1, "month")
      .subtract(1, "days");
    $scope.formData.dateTo = $scope.momentStop.toDate();
  }

  $scope.selectOptions = {};
  $scope.fetchCustProjects = function () {
    CustProjects.get({}, function (data) {
      $scope.selectOptions.customers = data;
      //console.log($scope.selectOptions.customers);
    });
  };

  $scope.releaseTimerecords = function (inv, index) {
    //console.log(inv.statement_id + ':' + inv.invoice_number);
    InvoiceOp.release(
      {
        statement_id: inv.statement_id,
        invoice_number: inv.invoice_number,
      },
      {},
      function (data) {
        if (
          typeof data.result !== "undefined" &&
          data.result.affectedRows > 0
        ) {
          //console.log(data);
        } else {
          //console.log("ERRROROORRRR" + data);
        }
      }
    );
  };

  $scope.listan = [{ koko: "dd" }, { koko: "ff" }, { koko: "fw" }];

  $scope.dutt = function () {
    //console.log($scope.listan);
  };

  $scope.edit = function (invoice, index) {
    var inv = $scope.invoice[index];

    //console.log("Edit " + inv.statement_id + ':' + inv.invoice_number);

    var editInv = angular.copy(inv);
    editInv.statement = JSON.parse(editInv.statement);

    if (typeof editInv.statement.org_statement === "undefined") {
      //console.log("backup org");
      editInv.statement.org_statement = angular.copy(editInv.statement);
    } else {
      //console.log("already edited");
    }

    var removeDetail = function (index) {
      if (editInv.statement.details.length > 1) {
        editInv.statement.details.splice(index, 1);
      }
    };

    var addDetail = function (index) {
      editInv.statement.details.splice(index, 0, {});
    };

    var save = function () {
      //console.log('save');
    };

    var cancel = function () {
      //console.log('cancel');
    };

    var modalDefaults = {
      template: editModal,
      windowClass: "app-modal-window",
    };

    var modalOptions = {
      closeButtonText: "Avbryt",
      actionButtonText: "OK",
      headerText: "Editera faktura",
      inv: editInv,
      index: index,
      save: save,
      cancel: cancel,
      removeDetail: removeDetail,
      addDetail: addDetail,
    };

    var ress = Modal.showModal(modalDefaults, modalOptions);

    ress.then(
      function () {
        //console.log('bra');
        //console.log(editInv.statement.details);

        editInv.statement.pris = 0;
        for (var m = 0, l = editInv.statement.details.length; m < l; m++) {
          editInv.statement.pris += editInv.statement.details[m].pris;
        }

        editInv.amount = editInv.statement.pris;

        editInv.statement = JSON.stringify(editInv.statement);
        inv = editInv;
        $scope.invoice[index] = inv;
        $scope.updateInvoiceNo(inv);
      },
      function () {
        //console.log('dåligt');
      }
    );
  };

  $scope.remove = function (inv, index) {
    //console.log(inv.statement_id + ':' + inv.invoice_number);
    InvoiceOp.get(
      {
        statement_id: inv.statement_id,
        invoice_number: inv.invoice_number,
      },
      {},
      function (data) {
        //console.log(data);
        $scope.invoice.splice(index, 1);
        if (
          typeof data.result !== "undefined" &&
          data.result.affectedRows > 0
        ) {
          //console.log(data);
        } else {
          //console.log("ERRROROORRRR" + data);
        }
      }
    );
  };

  $scope.updateInvoiceNo = function (inv) {
    //console.log(inv.statement_id + ':' + inv.invoice_number);
    InvoiceOp.update(
      {
        statement_id: inv.statement_id,
        invoice_number: inv.invoice_number,
        amount: inv.amount,
        statement: inv.statement,
      },
      {},
      function (data) {
        //console.log(data);
        if (
          typeof data.result !== "undefined" &&
          data.result.affectedRows === 1
        ) {
          //console.log(data);
        } else {
          //console.log("ERRROROORRRR" + data);
        }
      }
    );
  };

  $scope.fetchCustProjects();

  $scope.getHeader = function () {
    return $scope.header;
  };

  $scope.koko = function () {
    //console.log($scope.invoice);
    return $scope.invoice;
  };

  $scope.updatePeriod = function (dir) {
    $scope.momentStart = moment($scope.formData.dateFrom, "YYYY-MM-DD");
    $scope.momentStop = moment($scope.formData.dateTo, "YYYY-MM-DD");
    if (dir === -1) {
      $scope.momentStart.startOf("month").subtract(1, "months");
      $scope.momentStop.startOf("month").subtract(1, "days");
    } else {
      $scope.momentStart.startOf("month").add(1, "months");
      $scope.momentStop.startOf("month").add(2, "months").subtract(1, "days");
    }
    $scope.formData.dateFrom = $scope.momentStart.toDate();
    $scope.formData.dateTo = $scope.momentStop.toDate();
  };

  $scope.kaka = function (head, inv) {
    switch (head) {
      case "Kund":
        return inv.customername;

      case "Projekt":
        return inv.project_name;

      case "Kund Id":
        return inv.customer_id;

      case "Projekt Id":
        return inv.project_id;

      case "Period":
        return inv.period;

      case "Datum":
        return inv.inputdate;

      case "Summa":
        return inv.amount;
    }
  };

  $scope.kredit = function (inv, index) {
    var invParams = {};
    angular.copy(inv, invParams);

    var statement = JSON.parse(invParams.statement);
    if (statement.statementType === "kredit") {
      return;
    }

    invParams.amount = -invParams.amount;
    statement.pris = -statement.pris;
    for (var j = 0, len = statement.details.length; j < len; j++) {
      statement.details[j].pris = -statement.details[j].pris;
      statement.details[j].price = -statement.details[j].price;
    }

    statement.statementType = "kredit";
    invParams.statementType = statement.statementType;
    invParams.statement = JSON.stringify(statement);

    InvoiceIt.insert(
      {
        cust_id: invParams.customer_id,
        from: $scope.momentStart.format("YYYY-MM-DD"),
        to: $scope.momentStop.format("YYYY-MM-DD"),
      },
      invParams,
      function (invData) {
        $scope.invoiceData = invData[0];
        invParams.invoice_number = invData.invoice_number;
        //console.log('invoiceIt.insert ' + invParams.invoice_number);
        //invoicePdf.viewInvoice(invParams);
      }
    );
  };

  $scope.fetch = function () {
    var totAmm = 0;

    //spinnerService.show('invoiceReportSpinner1');

    $scope.header = ["Kund", "Projekt", "Datum", "Period", "Summa"];

    $scope.momentStart = moment($scope.formData.dateFrom);
    $scope.momentStop = moment($scope.formData.dateTo);

    var cust, proj;

    if (!$scope.selectOptions.customer) {
      cust = null;
    } else {
      cust = $scope.selectOptions.customer.id;
    }
    if (!$scope.selectOptions.project) {
      proj = null;
    } else {
      proj = $scope.selectOptions.project.id;
    }

    ReadInvoice.get(
      {
        cust: cust,
        proj: proj,
        from: $scope.momentStart.format("YYYY-MM-DD"),
        to: $scope.momentStop.format("YYYY-MM-DD"),
      },
      function (data) {
        //spinnerService.hide('invoiceReportSpinner1');
        $scope.invoice = data;
        for (var j = 0; j < $scope.invoice.length; j++) {
          try {
            $scope.invoice[j].inputdate = $filter("date")(
              $scope.invoice[j].inputdate,
              "yyyy-MM-dd"
            );
          } catch (error) {
            $scope.invoice[j].inputdate = null;
          }
          totAmm += $scope.invoice[j].amount;
        }
        $scope.totAmm = totAmm;
      }
    );
  };
}
