PasswordCtrl.$inject = ["$scope", "Auth"];

export default function PasswordCtrl($scope, Auth) {
  $scope.message = "Byt lösenord";

  Auth.get({}, function (success) {
    $scope.userName = success.username;
  });

  $scope.handlePwdChange = function (user) {
    //console.log($scope.userName);
    if ($scope.userName && user.currPwd) {
      Auth.changePwd(
        {},
        {
          username: $scope.userName,
          oldPassword: user.currPwd,
          newPassword: user.newPwd,
        },
        function (success) {
          //console.log(success);
          if (success.res !== "ok") {
            $scope.errorMessage = success.res;
            $scope.successMessage = null;
          } else {
            $scope.errorMessage = null;
            $scope.successMessage = "ok";
          }
        },
        function (error) {
          $scope.errorMessage = error.statusText;
          //console.log(error.statusText);
        }
      );
    } else {
      //console.log(user);
    }
  };
}
