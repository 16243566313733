VacationCtrl.$inject = [
  "$scope",
  "Vacation",
  "timeTravelMoment",
  "KcList",
  "moment",
  "Login",
];

export default function VacationCtrl(
  $scope,
  Vacation,
  timeTravelMoment,
  KcList,
  moment,
  Login
) {
  // Initial scope setup
  initializeScope();

  // Initialize date range
  initializeDateRange();

  // Load Subordinate data if the user is a chef
  loadSubordinateData();

  // Initialize dropdown settings
  initializeDropdownSettings();

  // Controller functions
  $scope.getHeader = getHeader;
  $scope.csvdata = getExportData;
  $scope.updatePeriod = updatePeriod;
  $scope.vacationdata = populateExportData;
  $scope.kallejonka = calculateWorkHours;
  $scope.fetchVacation = fetchVacation;

  // Functions Definitions

  function initializeScope() {
    $scope.fte = [];
    $scope.formData = {
      dateTo: null,
      dateFrom: null,
    };
    $scope.opened1 = false;
    $scope.opened2 = false;
    $scope.dateOptions = {
      "year-format": "'yy'",
      "show-weeks": true,
      "starting-day": 1,
    };
    $scope.daFormat = "yyyy Vww";
    $scope.message = "Semester";
    $scope.header = [];
    $scope.custMode = { external: true, internal: true };
    $scope.groupMode = "A";
    $scope.timeMode = "A";
    $scope.sumMode = "A";
  }

  function initializeDateRange() {
    const now = moment().startOf("day");
    const firstDayOfMonth = now.startOf("month").format("YYYY-MM-DD");

    $scope.momentStart =
      !$scope.formData.dateFrom && moment().date() > 15
        ? moment(firstDayOfMonth)
        : moment(firstDayOfMonth).add(-1, "month");

    $scope.formData.dateFrom = $scope.momentStart.toDate();

    $scope.momentStop =
      !$scope.formData.dateTo &&
      moment($scope.momentStart).add(3, "month").subtract(1, "days");

    $scope.formData.dateTo = $scope.momentStop.toDate();
  }

  function loadSubordinateData() {
    Login.promise
      .then(() => {
        if (Login.isKc() || Login.isAdmin()) {
          KcList.get(
            {},
            (data) => {
              $scope.Subordinate = data;
              return data;
            },
            () => null
          );
        }
      })
      .catch((error) => console.log("kan det stämma %s", error));
  }

  function initializeDropdownSettings() {
    $scope.myDropdownOptions = [
      { id: "S", label: "Standard" },
      { id: "I", label: "Intermediate" },
      { id: "B", label: "Best available" },
    ];
    $scope.myDropdownModel = [$scope.myDropdownOptions[0]];
    $scope.myDropdownSettings = {
      styleActive: true,
      checkBoxes: true,
      alwaysOpened: true,
      displayProp: "name",
      smartButtonMaxItems: 10,
    };
  }

  function getHeader() {
    return $scope.header;
  }

  function getExportData() {
    return $scope.exportData;
  }

  function updatePeriod(dir) {
    $scope.momentStart = moment($scope.formData.dateFrom, "YYYY-MM-DD");
    $scope.momentStop = moment($scope.formData.dateTo, "YYYY-MM-DD");

    if (dir === -1) {
      $scope.momentStart.subtract(1, "months").startOf("month");
      $scope.momentStop.subtract(1, "months").endOf("month");
    } else {
      $scope.momentStart.add(1, "months").startOf("month");
      $scope.momentStop.add(1, "months").endOf("month");
    }

    $scope.formData.dateFrom = $scope.momentStart.toDate();
    $scope.formData.dateTo = $scope.momentStop.toDate();
  }

  function populateExportData(head, inv, index, row) {
    if (!$scope.exportData[row]) {
      $scope.exportData[row] = [];
    }

    const exportValue = (value) =>
      value ? value.toLocaleString("sv-SE", { useGrouping: false }) : null;

    const exportCases = {
      NPC: inv.name,
    };

    Object.keys(inv)
      .filter((key) => Number(key))
      .forEach((key) => {
        exportCases["V " + key] = inv[key];
      });

    $scope.exportData[row][index] = exportCases[head] || null;
    return $scope.exportData[row][index];
  }

  function calculateWorkHours(from, to) {
    const momentStart = moment($scope.formData.dateFrom);
    const momentStop = moment($scope.formData.dateTo);

    let workHours = 0;
    const periods = {};

    for (let day = moment(momentStart); day <= momentStop; day.add(1, "days")) {
      if (
        timeTravelMoment.isRedDayMoment(day) ||
        timeTravelMoment.isWeekendDay(day)
      ) {
        continue;
      }

      const month = day.month() + 1;
      if (!periods[month]) {
        periods[month] = 0;
      }
      periods[month] += 8;
      workHours += 8;
    }

    $scope.periodHours = periods;
  }

  function fetchVacation() {
    $scope.vacation = [];
    $scope.exportData = [];

    setupHeaders();
    const ids = $scope.fte.map((fte) => fte.employee_id);

    Vacation.get(
      {
        id: ids,
        subTotal: $scope.subTotal,
        details: $scope.details,
        sum: $scope.sumMode,
        custMode: $scope.custMode,
        per: $scope.timeMode,
        type: $scope.groupMode,
        from: moment($scope.formData.dateFrom).format("YYYY-MM-DD"),
        to: moment($scope.formData.dateTo).format("YYYY-MM-DD"),
      },
      processVacationData
    );
  }

  function setupHeaders() {
    const groupModeHeaders = {
      A: ["NPC"],
      P: ["NPC"],
      KU: ["NPC"],
    };

    $scope.header = groupModeHeaders[$scope.groupMode] || [];
  }

  function processVacationData(data) {
    $scope.vacation = data.list;
    $scope.header.push(...data.weeks.map((week) => `V ${week}`));
  }

  function calculateColor(value) {
    const maxVal = 40;
    const minVal = 0;

    const maxColor = { r: 0, g: 102, b: 204 }; // Medium blue
    const minColor = { r: 230, g: 242, b: 255 }; // Light blue

    const ratio = value / maxVal;

    const r = Math.round(minColor.r + ratio * (maxColor.r - minColor.r));
    const g = Math.round(minColor.g + ratio * (maxColor.g - minColor.g));
    const b = Math.round(minColor.b + ratio * (maxColor.b - minColor.b));

    return `rgb(${r}, ${g}, ${b})`;
  }

  $scope.getStyleForValue = function (value) {
    if (Number.isInteger(value) && value > 0)
      return {
        "background-color": calculateColor(value),
        color: value > 20 ? "white" : "black", // Adjust text color for better readability
      };
  };

  $scope.getClassForValue = function (value) {
    console.log(value);
    if (Number.isInteger(value)) {
      if (value === 40) {
        return "value-40";
      } else if (value > 0) {
        return "value-between";
      }
    }
  };
}
